import React from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { ReactComponent as HeaderPhoneIcon } from "../../img/header-phone.svg";
import { _getPlatform, _isObjEmpty } from "../helpers";
import clsx from "clsx";
import ChooseTownModal from "../ChooseTownModal";
import { useSelector } from "react-redux";
import HeaderPhoneSkeleton from "../Skeletons/HeaderPhoneSkeleton";
import useMetricFunctions from "../../hooks/useMetricFunctions";

const HeaderPhoneBlock = ({ handleOpenTownModal }) => {
    const { mectricClickPhone } = useMetricFunctions();

    const config = useSelector((state) => state.config.data);

    const isConfigEmpty = useSelector((state) =>
        _isObjEmpty(state.config.data)
    );

    const formatPhone = config.CONFIG_format_phone;

    const town = config.CONFIG_town;

    const isTownChoiceAvailable =
        window.townsTypeRoute === "subDomain" ||
        window.townsTypeRoute === "folder";

    if (isConfigEmpty) {
        return <HeaderPhoneSkeleton />;
    }

    if (!formatPhone) {
        return (
            <div className="header-phone">
                <LocationOnIcon
                    className="main-color"
                    sx={{ fontSize: 40, height: "auto", mr: "0.5rem" }}
                />
                <div className="header-phone--content">
                    <div className="title">
                        <div>Доставка еды</div>
                        <b
                            className={clsx(
                                "choosenTown",
                                "town",
                                isTownChoiceAvailable && "link"
                            )}
                            onClick={
                                isTownChoiceAvailable
                                    ? handleOpenTownModal
                                    : null
                            }
                        >
                            {town}
                        </b>
                    </div>
                </div>
                <ChooseTownModal />
            </div>
        );
    }
    return (
        <div className="header-phone">
            <HeaderPhoneIcon className="icn" />
            <div className="header-phone--content">
                {isTownChoiceAvailable ? (
                    <div className="title">
                        <b
                            className="choosenTown"
                            style={{
                                cursor: "pointer",
                            }}
                            onClick={handleOpenTownModal}
                            title={town}
                        >
                            {town}
                        </b>
                    </div>
                ) : (
                    <div className="title">
                        Доставка еды <b className="choosenTown">{town}</b>
                    </div>
                )}
                {
                    <a
                        className="info"
                        href={`tel:${formatPhone.replace(/[^0-9+]+/g, "")}`}
                        onClick={mectricClickPhone}
                        target="_parent"
                    >
                        {formatPhone}
                    </a>
                }
            </div>
            <ChooseTownModal />
        </div>
    );
};

export default HeaderPhoneBlock;
