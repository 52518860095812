import { useDispatch, useSelector } from "react-redux";
import { completelyRemovePromocode } from "../../redux/actions/cart";
import { Button } from "@mui/material";
import ProductSalePrice from "./ProductSalePrice";
import CloseIcon from "@mui/icons-material/Close";
import soon from "../../img/photo-soon.svg";
import "../../css/cart.css";

export default function PromocodeMiniCartProduct() {
    const dispatch = useDispatch();

    const promocodeProduct = useSelector(({ cart }) => cart.promocodeProducts);

    const promocode = useSelector(({ cart }) => cart.promocode);

    const handleDecreaseProduct = () => {
        dispatch(completelyRemovePromocode());
    };

    const handleRemoveProduct = () => {
        dispatch(completelyRemovePromocode());
    };

    let dataAttributes = {};
    if (promocodeProduct.attributes) {
        Object.values(promocodeProduct.attributes).forEach((value, index) => {
            dataAttributes[index] = value.name;
        });
    }

    return (
        <div
            className="minicart--product"
            data-product-id={promocodeProduct.id}
        >
            <div className="minicart--product-info">
                <div className="minicart--product-image">
                    <img
                        src={promocodeProduct.img ? promocodeProduct.img : soon}
                        alt={promocodeProduct.title}
                    />
                </div>
                <div className="minicart--product-inner">
                    <div className="minicart--product-title">
                        <span className="minicart--product-name">
                            {promocodeProduct.title}
                        </span>
                        <span
                            className="minicart--product-remove"
                            onClick={handleRemoveProduct}
                            data-product-id={promocodeProduct.id}
                        >
                            <CloseIcon />
                        </span>
                    </div>
                    <div className="minicart--product-description">
                        {promocodeProduct.type === "variations" ? (
                            <div className="minicart--product-attributes">
                                {Object.values(
                                    promocodeProduct.variant.attributes
                                ).map((attribute, keyAttr) => (
                                    <div
                                        className="minicart--product-attribute"
                                        key={keyAttr}
                                    >
                                        {dataAttributes[keyAttr]}: {attribute}
                                    </div>
                                ))}
                            </div>
                        ) : null}

                        {promocodeProduct.type === "variations" ? (
                            promocodeProduct.variant.weight && (
                                <div className="weight">
                                    {promocodeProduct.variant.weight} гр.
                                </div>
                            )
                        ) : (
                            <div>
                                {promocodeProduct.options.weight ? (
                                    <div className="weight">
                                        {promocodeProduct.options.weight} гр.
                                    </div>
                                ) : (
                                    ""
                                )}
                                {promocodeProduct.options.count_rolls ? (
                                    <div className="count-rolls">
                                        {promocodeProduct.options.count_rolls}{" "}
                                        шт.
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        )}
                        <div className="promocode-name">
                            {" "}
                            Промокод «{promocode.code}»
                        </div>
                    </div>
                </div>
            </div>
            <div className="minicart--product-result">
                <ProductSalePrice
                    oldPrice={
                        promocodeProduct.type === "variations" &&
                        promocodeProduct.variant
                            ? promocodeProduct.variant.price
                            : promocodeProduct.options._price
                    }
                    price={promocodeProduct.options._promocode_price}
                />

                <div className="minicart--product-quantity">
                    <Button
                        className="btn--default product-decrease"
                        onClick={handleDecreaseProduct}
                    >
                        -
                    </Button>
                    <input
                        className="quantity"
                        type="text"
                        readOnly
                        value={1}
                        data-product-id={promocodeProduct.id}
                    />
                    <Button
                        className="btn--default product-add btn-buy"
                        disabled
                    >
                        +
                    </Button>
                </div>
            </div>
        </div>
    );
}
