import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeProductFromCart } from "../../redux/actions/cart";
import CloseIcon from "@mui/icons-material/Close";
import soon from "../../img/photo-soon.svg";
import "../../css/cart.css";
import MiniCartProductBuyBtn from "components/MiniCart/MiniCartProductBuyBtn";
import ProductSalePrice from "./ProductSalePrice";

export default function MiniCartProduct({
    productCart,
    productCount,
    productTotalPrice,
    productIndex,
}) {
    const dispatch = useDispatch();

    const { promocodeProducts, promocode } = useSelector(({ cart }) => {
        return {
            promocodeProducts: cart.promocodeProducts,
            promocode: cart.promocode,
        };
    });

    const handleRemoveProduct = () => {
        dispatch(
            removeProductFromCart({
                ...productCart,
                productIndex: productIndex,
            })
        );
    };

    let dataAttributes = {};
    if (productCart.attributes) {
        Object.values(productCart.attributes).forEach((value, index) => {
            dataAttributes[index] = value.name;
        });
    }

    const renderMinicartProductResult = () => {
        // Цена по промокоду на %
        if (parseInt(productCart.options._promocode_price)) {
            if (productCart.type === "variations") {
                return (
                    <ProductSalePrice
                        oldPrice={productCart.options._price}
                        price={productCart.options._promocode_price}
                    />
                );
            } else {
                return (
                    <ProductSalePrice
                        oldPrice={productCart.options._price * productCount}
                        price={productTotalPrice}
                    />
                );
            }
        }

        // Цена товара со скидкой
        if (
            productCart.type === "simple" &&
            parseInt(productCart.options._regular_price) >
                parseInt(productCart.options._sale_price)
        ) {
            return (
                <ProductSalePrice
                    oldPrice={
                        productCart.options._regular_price * productCount +
                        (productCart.modificatorsAmount ?? 0)
                    }
                    price={productTotalPrice}
                />
            );
        }
        if (
            productCart.type === "variations" &&
            parseInt(productCart.variant?._regular_price) >
                parseInt(productCart.variant?.price)
        ) {
            return (
                <ProductSalePrice
                    oldPrice={
                        parseInt(productCart.variant?._regular_price) +
                        productCart.modificatorsAmount
                    }
                    price={productCart.options._price}
                />
            );
        }

        // Цена без скидкой и промокодов
        if (productCart.type === "variations") {
            return (
                <span>
                    {parseInt(productCart.options._price).toLocaleString(
                        "ru-RU"
                    )}{" "}
                    &#8381;
                </span>
            );
        }
        return (
            <span>
                {parseInt(productTotalPrice).toLocaleString("ru-RU")} &#8381;
            </span>
        );
    };

    const minicartProductResult = renderMinicartProductResult();

    return (
        <div className="minicart--product" data-product-id={productCart.id}>
            <div className="minicart--product-info">
                <div className="minicart--product-image">
                    <img
                        src={productCart.img ? productCart.img : soon}
                        alt={productCart.title}
                    />
                </div>
                <div className="minicart--product-inner">
                    <div className="minicart--product-title">
                        <span className="minicart--product-name">
                            {productCart.title}
                        </span>
                        <span
                            className="minicart--product-remove"
                            onClick={handleRemoveProduct}
                            data-product-id={productCart.id}
                        >
                            <CloseIcon />
                        </span>
                    </div>
                    <div className="minicart--product-description">
                        {productCart.type === "variations" ? (
                            <div className="minicart--product-attributes">
                                {Object.values(
                                    productCart.variant.attributes
                                ).map((attribute, keyAttr) => (
                                    <div
                                        className="minicart--product-attribute"
                                        key={keyAttr}
                                    >
                                        {dataAttributes[keyAttr]}: {attribute}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        {productCart.choosenModificators?.length ? (
                            <div className="minicart--product-attributes">
                                +{" "}
                                {productCart.choosenModificators.map(
                                    (el, inx, array) =>
                                        inx !== array.length - 1 ? (
                                            <span key={el.id}>
                                                {el.title} {el.count} шт.,{" "}
                                            </span>
                                        ) : (
                                            <span key={el.id}>
                                                {el.title} {el.count} шт.
                                            </span>
                                        )
                                )}
                            </div>
                        ) : null}

                        {productCart.type === "variations" ? (
                            productCart.variant.weight && (
                                <div className="weight">
                                    {productCart.variant.weight} гр.
                                </div>
                            )
                        ) : (
                            <div>
                                {productCart.options.weight ? (
                                    <div className="weight">
                                        {productCart.options.weight} гр.
                                    </div>
                                ) : (
                                    ""
                                )}
                                {productCart.options.count_rolls ? (
                                    <div className="count-rolls">
                                        {productCart.options.count_rolls} шт.
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </div>

            <div className="minicart--product-result">
                {minicartProductResult}
                <MiniCartProductBuyBtn
                    product={productCart}
                    productCount={productCount}
                />
            </div>
        </div>
    );
}
