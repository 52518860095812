import React, { useState, useEffect, useMemo, useCallback } from "react";
import { useNavigate } from "react-router";
import { CircularProgress, TextField } from "@mui/material";
import Fuse from "fuse.js";
import { _getPlatform } from "./helpers";
import clsx from "clsx";
import "../css/choose-town.css";

export default function ChooseTown({ onChooseTown = () => {} }) {
    const navigate = useNavigate();

    const [inputValue, setInputValue] = useState("");
    const [filteredTowns, setFilteredTowns] = useState(null);
    const [loading, setLoading] = useState(false);

    const fuse = new Fuse(window.towns, {
        keys: ["name"],
        minMatchCharLength: 1,
        threshold: 0.2,
    });

    const handleChooseTown = (town) => {
        if (window.townsTypeRoute === "folder") {
            navigate(`/${town.slug}`);
        } else {
            let url = town.url;

            if (_getPlatform() === "vk") {
                url += "?rest-api=vk_start";
            } else if (_getPlatform() === "tg") {
                url += "?rest-api=tg_start";
            } else {
                url += `?platform=${_getPlatform()}`;
            }

            window.location.href = url;
            setLoading(true);
        }

        onChooseTown();
    };

    const inputChangeHandler = useCallback((event) => {
        setInputValue(event.target.value);
        if (!event.target.value) {
            setFilteredTowns(groupedTowns);
            return;
        }
        const temp = fuse.search(event.target.value).map((el) => el.item);
        setFilteredTowns(groupTowns(temp));
    }, []);

    useEffect(() => {
        setFilteredTowns(groupedTowns);
    }, [window.towns]);

    const groupTowns = useCallback((arr) => {
        const map = arr.reduce((acc, val) => {
            if (val.name) {
                let char = val.name.charAt(0).toUpperCase();
                acc[char] = [].concat(acc[char] || [], val);
                return acc;
            } else return acc;
        }, {});
        const res = Object.keys(map).map((el) => ({
            letter: el,
            towns: map[el],
        }));
        const sortedRes = res.sort((a, b) => {
            if (a.letter < b.letter) {
                return -1;
            }
            if (a.letter > b.letter) {
                return 1;
            }
            return 0;
        });
        return sortedRes;
    }, []);

    const groupedTowns = useMemo(() => {
        if (window.towns?.length) {
            const initial = groupTowns(window.towns);
            return initial;
        }
    }, [window.towns]);

    const renderedTownsName =
        filteredTowns &&
        filteredTowns.map((group, index) => {
            return (
                <div className="town-group" key={index}>
                    <span className="town-letter">{group.letter}</span>
                    {group.towns.map((town, index) => (
                        <div
                            key={index}
                            className="town-link"
                            onClick={() => handleChooseTown(town)}
                        >
                            {town.name}
                        </div>
                    ))}
                </div>
            );
        });

    return (
        <div className="modal-alert--inner">
            <div
                className={clsx("choose-town--backdrop", loading && "visible")}
            >
                <CircularProgress
                    size={40}
                    sx={{ color: window.CONFIG_main_color }}
                />
            </div>

            <TextField
                size="small"
                label="Поиск"
                value={inputValue}
                onChange={inputChangeHandler}
                sx={{
                    mb: 2,
                    "& fieldset": {
                        borderRadius: "20px",
                    },
                }}
                fullWidth
            />
            {renderedTownsName}
        </div>
    );
}
