import React from "react";
import { useDispatch } from "react-redux";
import { addBonusProductToCart } from "../../redux/actions/cart";
import Button from "@mui/material/Button";
import ProductSalePrice from "./ProductSalePrice";
import CloseIcon from "@mui/icons-material/Close";
import "../../css/cart.css";
import soon from "../../img/photo-soon.svg";

export default function MiniCartBonusProduct({
    productCart,
    productCount,
    productTotalPrice,
}) {
    const dispatch = useDispatch();

    const handleChooseBonusProduct = (item) => {
        dispatch(addBonusProductToCart(item));
    };

    let dataAttributes = {};
    if (productCart.attributes) {
        Object.values(productCart.attributes).forEach((value, index) => {
            dataAttributes[index] = value.name;
        });
    }

    let productPrice = parseInt(productTotalPrice);

    if (productCart.modificatorsAmount) {
        productPrice += productCart.modificatorsAmount;
    }

    return (
        <div className="minicart--product" data-product-id={productCart.id}>
            <div className="minicart--product-info">
                <div className="minicart--product-image">
                    <img
                        src={productCart.img ? productCart.img : soon}
                        alt={productCart.title}
                    />
                </div>
                <div className="minicart--product-inner">
                    <div className="minicart--product-title">
                        <span className="minicart--product-name">
                            {productCart.title}
                        </span>
                        <span
                            className="minicart--product-remove"
                            onClick={() => handleChooseBonusProduct({})}
                            data-product-id={productCart.id}
                        >
                            <CloseIcon />
                        </span>
                    </div>
                    <div className="minicart--product-description">
                        {productCart.type === "variations" &&
                        productCart.variant?.attributes ? (
                            <div className="minicart--product-attributes">
                                {Object.values(
                                    productCart.variant.attributes
                                ).map((attribute, keyAttr) => (
                                    <div
                                        className="minicart--product-attribute"
                                        key={keyAttr}
                                    >
                                        {dataAttributes[keyAttr]}: {attribute}
                                    </div>
                                ))}
                            </div>
                        ) : null}
                        {productCart.choosenModificators?.length ? (
                            <div className="minicart--product-attributes">
                                +{" "}
                                {productCart.choosenModificators.map(
                                    (el, inx, array) =>
                                        inx !== array.length - 1 ? (
                                            <span key={el.id}>
                                                {el.title} {el.count} шт.,{" "}
                                            </span>
                                        ) : (
                                            <span key={el.id}>
                                                {el.title} {el.count} шт.
                                            </span>
                                        )
                                )}
                            </div>
                        ) : null}

                        {productCart.type === "variations" &&
                        productCart.variant?.weight ? (
                            productCart.variant.weight && (
                                <div className="weight">
                                    {productCart.variant.weight} гр.
                                </div>
                            )
                        ) : (
                            <div>
                                {productCart.options.weight ? (
                                    <div className="weight">
                                        {productCart.options.weight} гр.
                                    </div>
                                ) : (
                                    ""
                                )}
                                {productCart.options.count_rolls ? (
                                    <div className="count-rolls">
                                        {productCart.options.count_rolls} шт.
                                    </div>
                                ) : (
                                    ""
                                )}
                            </div>
                        )}
                    </div>
                    {/* <div className="minicart--product-price">{productCart.options._price.toLocaleString('ru-RU')} &#8381;</div> */}
                </div>
            </div>
            <div className="minicart--product-result">
                <ProductSalePrice
                    oldPrice={productPrice}
                    isBonusProduct={true}
                />

                <div className="minicart--product-quantity">
                    <Button
                        className="btn--default product-decrease"
                        onClick={() => handleChooseBonusProduct({})}
                    >
                        -
                    </Button>
                    <input
                        className="quantity"
                        type="text"
                        readOnly
                        value={productCount}
                        data-product-id={productCart.id}
                    />
                    <Button className="btn--default product-add" disabled>
                        +
                    </Button>
                </div>
            </div>
        </div>
    );
}
